.main {
  border-bottom: 2px solid #fac83a;
}

.title {
  display: block;
  margin-top: -4px;
  margin-bottom: 4px;
  @media screen and (min-width: 1036px) {
    display: inline-block;
  }
}
