.container {
  position: relative;
  display: block;
  background-color: #f5f5f5;
  border: 2px solid #f5f5f5;
  border-radius: 5px;
  transition: 0.3s ease all;
  padding: 20px;
  height: 100%;
  text-align: center;
  opacity: 1;
}

.container:hover {
  border-color: #fac83a;
  background-color: #fff;
  opacity: 1;
}

.whiteBackground {
  background-color: #fff;
  border: 2px solid #fff;
}

.whiteBackground:hover {
  border-color: #f5f5f5;
  background-color: #f5f5f5;
}

.image {
  max-width: 150px;
  max-height: 150px;
  display: inline-block;
}

.container:hover .mainImage {
  display: none;
}

.subImage {
  display: none;
}

.container:hover .subImage {
  display: inline-block;
}

@media screen and (max-width: 1036px) {
  .main {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.imageContainer {
  width: 45%;
  padding-right: 20px;
}

.detail {
  text-align: center;
}

@media screen and (max-width: 1036px) {
  .detail {
    text-align: left;
    width: 55%;
  }
}

.description {
  text-align: left;
}

.tag {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  border-radius: 5px;
  margin-top: 10px;
  margin-left: 0;
  line-height: 24px;
  font-size: 0.75rem;
  padding: 0 10px;
  border: none;
  font-weight: bold;
}

.newBadge {
  position: absolute;
  left: -10px;
  top: -10px;
  background-color: #ff6478;
  height: 50px;
  width: 50px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  border-radius: 50%;
  line-height: 50px;
}
